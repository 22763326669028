







import { Component, Vue } from 'vue-property-decorator'

import faqWrapper from '@/components/faq/faq-wrapper.vue'
import VideoSection from '../components/faq/video-section.vue'

@Component({
  components: {
    faqWrapper,
    VideoSection,
  },
  metaInfo() {
    const faq = this.$t('header.faq')

    return {
      title: `Sweevy | ${faq}`,
      meta: [
        {
          name: 'description',
          content:
            'Sweevy is een community, dóór bewegingsdeskundigen, vóór bewegingsdeskundigen. Vanuit onze er varing als kinesitherapeut en bewegingsdeskundige leerden we dat onze stiel dringend nood had aan digitalisering',
        },
      ],
    }
  },
})
export default class extends Vue {}
