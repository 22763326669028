<template>
  <transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
    <slot />
  </transition>
</template>

<script>
/* eslint-disable */
export default {
  methods: {
    enter(element) {
      const { width } = getComputedStyle(element)

      element.style.width = width
      element.style.position = 'absolute'
      element.style.visibility = 'hidden'
      element.style.height = 'auto'

      const { height } = getComputedStyle(element)

      element.style.width = ''
      element.style.position = ''
      element.style.visibility = ''
      element.style.height = ''

      getComputedStyle(element).height

      requestAnimationFrame(() => {
        element.style.height = height
      })
    },

    afterEnter(element) {
      element.style.height = 'auto'
    },

    leave(element) {
      const { height } = getComputedStyle(element)

      element.style.height = height
      getComputedStyle(element).height

      requestAnimationFrame(() => {
        element.style.height = '0'
      })
    },
  },
}
</script>

<style scoped>
.expand-enter-active,
.expand-leave-active {
  transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}

* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>
