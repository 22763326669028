<template>
  <section class="faq container">
    <!-- <header>
      <h1>{{ $t('faq.title') }}</h1>

      <input-element v-model="search" :placeholder="$t('faq.search')">
        <template #append>
          <button-element icon="search" class="search" />
        </template>
      </input-element>
    </header> -->
    <header>
      <h1>FAQ</h1>

      <p>
        {{ $t('faq.subtitle') }}
      </p>

      <input-element
        v-model="search"
        :title="$t('faq.search')"
        :placeholder="$t('faq.search_for_specific_subject')"
        class="search"
      />
    </header>

    <h2>{{ $t('faq.title') }}</h2>

    <div v-if="filteredFaq.length" class="wrapper">
      <!-- <transition-group name="faq-list"> -->
      <div>
        <FaqItem
          v-for="question of filteredFaq.slice(0, Math.ceil(filteredFaq.length / 2))"
          :key="question.question"
          :faq="question"
          class="faq-list-item"
        />
      </div>

      <div>
        <FaqItem
          v-for="question of filteredFaq.slice(Math.ceil(filteredFaq.length / 2))"
          :key="question.question"
          :faq="question"
          class="faq-list-item"
        />
      </div>
      <!-- </transition-group> -->
    </div>

    <p v-if="search && search.length && !filteredFaq.length" class="no-results">
      {{ $t('faq.no_results') }} '{{ search }}'
    </p>

    <!-- <div class="faq__footer">
      <h3>{{ $t('faq.footer_title') }}</h3>
      <p>
        {{ $t('faq.footer_subtitle') }} <a href="mailto:support@sweevy.be">support@sweevy.be</a>
      </p>
    </div> -->
  </section>
</template>

<script>
import { inputElement } from '@/elements'

import FaqItem from './faq-item.vue'

export default {
  components: {
    inputElement,
    // buttonElement,
    FaqItem,
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      search: null,
    }
  },

  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    faq() {
      return this.$t('faq.items')
    },

    filteredFaq() {
      if (!this.search || !this.search.length) {
        return this.faq
      }

      return this.faq.filter(
        (faq) =>
          faq.question.toLowerCase().includes(this.search.toLowerCase()) ||
          faq.answer
            .join('')
            .toLowerCase()
            .includes(this.search.toLowerCase()),
      )
    },
  },
}
</script>

<style scoped lang="scss">
.faq {
  margin: 4rem 0;
  overflow: hidden;

  // header {
  //   margin-bottom: 2rem;

  //   h1 {
  //     font-size: 1.7rem;
  //     text-align: center;

  //     margin-bottom: 3rem;
  //   }

  //   .input-el {
  //     max-width: 500px;
  //     margin: 0 auto;
  //   }
  // }

  header {
    text-align: center;
    margin-bottom: 4rem;

    h1 {
      font-weight: bold;
      font-size: 2.2rem;
      margin-bottom: 2rem;
    }

    p {
      max-width: 650px;
      margin: 0 auto 2rem auto;
      line-height: 1.5;
    }
  }

  h2 {
    font-size: var(--text-2xl);
    margin-bottom: 1.5rem;
  }

  &__footer {
    margin-top: 4rem;

    h3 {
      font-size: var(--text-2xl);
      margin-bottom: 1rem;
    }

    h3,
    p {
      text-align: center;
    }

    p {
      line-height: 1.5;
      font-size: var(--text-sm);
      color: var(--text-tertiary);

      a {
        color: var(--accent-primary);
        text-decoration: underline;
      }
    }
  }
}

.no-results {
}

.search {
  max-width: 500px;
  margin: 0 auto;
}

::v-deep .input-el__content {
  background: rgb(210, 225, 248);
  border: none !important;

  input::placeholder {
    color: rgb(116, 133, 165);
  }
}

::v-deep .input__wrapper-el__content {
  color: var(--accent-secondary);
}

.container {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;

  padding-left: 1rem;
  padding-right: 1rem;

  @include breakpoint(tablet) {
    padding-left: 2rem;
    padding-right: 2rem;

    header {
      h1 {
        font-size: 2.5rem;
      }
    }
  }
}

.wrapper {
  position: relative;

  &>div:nth-of-type(2) {
    padding-bottom: 1rem;
  }

  &>div:nth-of-type(1) {
    margin-bottom: 1rem;
  }

  @include breakpoint(tablet) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    grid-auto-rows: minmax(min-content, max-content);
    align-items: flex-start;

    &>div:nth-of-type(1) {
      margin-bottom: 0;
      padding-bottom: 1rem;
    }

    &>div:nth-of-type(2) {
      padding-bottom: 1rem;
    }
  }
}

.faq-list-item {
  transition: all 0.3s;
  width: 100%;
}

.faq-list-enter,
.faq-list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.faq-list-leave-active {
  position: absolute;
}
</style>
