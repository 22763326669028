



























import { Component, Vue } from 'vue-property-decorator'

import sectionElement from '@/components/page/page-section.vue'
import container from '@/components/page/page-container.vue'

@Component({
  components: {
    sectionElement,
    container,
  },
})
export default class extends Vue {
  videos = [
    {
      // eslint-disable-next-line global-require
      url: require('@/assets/videos/add_colleague.mp4'),
      description: 'videos.add_colleague',
    },
    {
      // eslint-disable-next-line global-require
      url: require('@/assets/videos/create_client.mp4'),
      description: 'videos.create_client',
    },
    {
      // eslint-disable-next-line global-require
      url: require('@/assets/videos/add_exercise.mp4'),
      description: 'videos.add_exercise',
    },
    {
      // eslint-disable-next-line global-require
      url: require('@/assets/videos/create_scheme.mp4'),
      description: 'videos.create_scheme',
    },
  ]
}
