<template>
  <div
    class="faq-item"
    :class="{ 'faq-item--open': showAnswer }"
    role="button"
    @click="showAnswer = !showAnswer"
  >
    <div class="faq-item__question">
      <h3>{{ faq.question }}</h3>
      <div class="faq-item__question__icon">
        <span />
        <span />
      </div>
    </div>

    <HeightTransition>
      <div v-if="showAnswer" class="faq-item__answer">
        <div>
          <p v-for="p of faq.answer" :key="p" v-html="p" />
        </div>
      </div>
    </HeightTransition>
  </div>
</template>

<script>
import HeightTransition from './faq-item-height-transition.vue'

export default {
  components: {
    HeightTransition,
  },

  props: {
    faq: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showAnswer: false,
    }
  },
}
</script>

<style scoped lang="scss">
.faq-item {
  padding: 1.5rem;
  // border: 1px solid var(--border-color-primary);
  border-radius: var(--border-radius-md);
  background: var(--background-primary);

  box-shadow: var(--box-shadow);

  cursor: pointer;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  &__question {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      transition: color 0.2s;
      line-height: 1.4;
      max-width: 90%;
      font-weight: bold;
      font-size: var(--text-sm);
    }

    &__icon {
      position: relative;
      width: 12px;
      height: 12px;

      span {
        position: absolute;
        top: 50%;
        left: 50%;

        width: 100%;
        height: 2px;

        background: var(--text-secondary);

        transform: translate(-50%, -50%);

        transition: 0.2s;

        &:nth-of-type(2) {
          transform: translate(-50%, -50%) rotate(90deg);
          transition-delay: 0.05s;
        }
      }
    }
  }

  &__answer {
    & > div {
      padding-top: 1rem;

      p {
        line-height: 1.4;
        font-size: var(--text-xs);

        &:not(:last-of-type) {
          margin-bottom: 0.7rem;
        }

        ::v-deep a {
          color: var(--accent-primary);

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &--open &__question {
    // h2 {
    //   color: var(--accent-primary);
    // }

    &__icon {
      span {
        &:nth-of-type(1) {
          transform: translate(-50%, -50%) rotate(90deg);
          opacity: 0;
        }

        &:nth-of-type(2) {
          transform: translate(-50%, -50%) rotate(180deg);
        }
      }
    }
  }

  @include breakpoint(tablet) {
    &__question {
      h2 {
        font-size: 1em;
      }
    }

    &__answer {
      & > div {
        p {
          font-size: var(--text-sm);
          max-width: 90%;
        }
      }
    }
  }
}
</style>
